import React, { useEffect } from 'react';
import { Route, Navigate, useNavigate } from 'react-router-dom';

const ProtectedRoute = (props) => {
  const {Component} = props;
  const navigate = useNavigate()

  useEffect(() => {
    let login = localStorage.getItem('token');
    if(!login){
      navigate('/login')
    }
  })
  return (
        <div>
          <Component />
        </div>
  );
};

export default ProtectedRoute;
