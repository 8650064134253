import React, { Fragment, useState } from 'react'
import { IoCloseSharp } from "react-icons/io5";
import { IoSearchOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


function Search() {
    const [searchQuery, setSearchQuery] = useState('');
    const [saleCards, setSaleCards] = useState([]);
    const navigate = useNavigate();

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        console.log(event.target.value)
        searchBooks(); // Call the searchBooks function whenever the search query changes
    };

    const gotoCard = (id) => {
        navigate(`/buyTittle/${id}`)
    }

    const searchBooks = async () => {
        try {
            const response = await axios.post('https://mrbookbackend-96a285b41bf3.herokuapp.com/api/search', { search_input: searchQuery });
            setSaleCards(response.data.products)
            console.log("here is the ", response.data.products)
            // setSearchResults(response.data.results);
        } catch (error) {
            console.error('Error searching books:', error);
        }
    };


    return (
        <Fragment>
            <div className="absolute h-full inset-0 z-50 flex items-center justify-center bg-black bg-opacity-25">
                <div className="bg-white overflow-y-auto h-full  w-[700px] p-4 rounded-lg shadow-lg">
                    <div className="flex w-full justify-between pb-8 gap-x-10">
                        <h1></h1>
                        <h1 className="text-2xl font-bold text-yellow-600">Search Our site</h1>
                        <IoCloseSharp className="text-2xl cursor-pointer" onClick={() => navigate('/')}/>
                    </div>
                    <div className='relative w-full items-center text-center'>
                        <input
                            type="text"
                            className='border-2 border-yellow-600 rounded-full px-2 w-full py-2'
                            placeholder='Search here'
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                        />

                        <div className="absolute top-2 right-4">
                            <IoSearchOutline className="text-2xl" />
                        </div>
                    </div>

                    {searchQuery === '' ? (
                        <div className="text-center py-4">Quick Search : <span className="text-yellow-600">Book Name</span></div>
                    ) : (
                        <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-y-6 gap-x-5 md:gap-x-4 lg:gap-x-3 px-4 py-10">
                            {saleCards?.map((saleItem, index) => (
                                <div key={index} className="w-full">
                                    <div className="w-full flex items-center justify-center cursor-pointer">
                                        <img src={saleItem.url} className="w-[200px] h-[230px] " alt="" onClick={() => gotoCard(saleItem._id)} />
                                    </div>

                                    <div className="flex flex-col justify-center items-center py-2">
                                        <h1 className="font-semibold text-xs sm:text-xs md:text-md lg:text-xl">
                                            {saleItem.title}
                                        </h1>
                                        <div className="flex gap-x-3">
                                            <h1 className="text-xs lg:text-lg text-gray-500 line-through">
                                                Rs{saleItem.price}
                                            </h1>
                                            <h1 className="text-xs lg:text-lg font-semibold">Rs-{saleItem.salePrice}</h1>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default Search