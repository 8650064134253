import React, {useState, useEffect} from "react";
import Menu from "../menu/Menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";


function Profile() {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear all items from local storage
    localStorage.clear();
    
    // Redirect the user to the login page or any other desired page
    navigate('/'); // Assuming '/login' is the route for the login page
  };

  useEffect(() => {
    // Your API endpoint URL
    const apiUrl = "https://mrbookbackend-96a285b41bf3.herokuapp.com/api/usersbyid";

    // Your bearer token
    const token = localStorage.getItem("token");

    // Configuring the Axios request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Making the API request
    axios.get(apiUrl, axiosConfig)
      .then((response) => {
        // Update the state with the fetched user data
        console.log("object", response.data);
        setUserData(response.data.body);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []); // Empty dependency array to run the effect only once


  console.log(userData?.first_name);
  return (
    <div>
      <Menu />
      <div className="">
        <h1 className=" items-center justify-center font-bold text-4xl hidden sm:block sm:pt-16">
          Profiles
        </h1>

        <div className="w-full h-full px-2 sm:px-10 pt-10 flex flex-col sm:flex-row gap-x-20">
          <div className="w-full flex flex-row justify-center sm:flex-col sm:w-[30%] h-full border rounded-2xl">
            <Link to='/profile' className="py-2 sm:px-2 text-sm w-full items-center text-center border bg-[#f8b84e]">Dashboard</Link>
            {/* <Link to='/address' className="py-2 sm:px-2 text-sm w-full items-center text-center border">Address</Link>
            <Link to='/orderhistory' className="py-2 sm:px-2 text-sm w-full items-center text-center border">Orders History</Link> */}
          <Link onClick={handleLogout} className="py-2 sm:px-2 text-sm w-full items-center text-center border">Logout</Link>

          </div>

          <div className="bg-gray-400 hidden sm:block h-[400px] w-[2px]"></div>

          <div className="w-full pb-8">
            <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-start gap-x-4 pt-4">
              {/* <div>
                <img src={profileimg} className="rounded-full sm:w-[90px] sm:h-[90px] w-[70px] h-[70px]" alt="" />
              </div> */}
              <div className="flex flex-col justify-center items-center sm:items-start sm:gap-y-3">
                <h1 className="text-xl font-semibold">{userData?.first_name}</h1>
                {/* <h1 className="text-sm sm:text-xl pt-0 sm:pt-2">{userData?.email}</h1> */}
              </div>
            </div>

            <div className="w-full">
              {/* <h1 className="font-semibold text-2xl pt-3">Account Info</h1> */}

              <div className="flex pt-4 w-full">
                <div className="w-28">
                  <h1 className="border border-gray-500 p-3">First name</h1>
                  <h1 className="border border-gray-500 p-3">Last name</h1>
                  <h1 className="border border-gray-500 p-3">Email</h1>
                  {/* <h1 className="border border-gray-500 p-3">Gender</h1> */}
                </div>

                <div className="w-[70%]">
                  <h1 className="border border-gray-500 w-full p-3">{userData?.first_name}</h1>
                  <h1 className="border border-gray-500 w-full p-3">{userData?.last_name}</h1>
                  <h1 className="border border-gray-500 w-full p-3">{userData?.email}</h1>
                  {/* <h1 className="border border-gray-500 w-full p-3">{userData?.gender}</h1> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* for mobile screen */}
      {/* <div className="block sm:hidden">
        <div className="flex flex-col items-center pt-8">
          <img src={profileimg} className="rounded-full w-[80px]" alt="" />
          <h1>Name</h1>
          <h1>example@gmial.com</h1>
        </div>

        <h1 className="pt-6 ml-4 font-semibold text-2xl">Account</h1>
        <div className="flex pt-3 gap-x-3">
          <div className="w-[1px] h-[170px] bg-gray-500 ml-5"></div>
          <div className="flex flex-col gap-y-3">
              <Link to='/mobileProfile'><h1 className="font-semibold text-xl text-gray-500">Dashboard</h1></Link>
              <Link><h1 className="font-semibold text-xl text-gray-500">Your Address</h1></Link>
              <Link><h1 className="font-semibold text-xl text-gray-500">Order history</h1></Link>
              <Link><h1 className="font-semibold text-xl text-gray-500">Logout</h1></Link>
          </div>
        </div>
      </div> */}


    </div>
  );
}

export default Profile;
