import Whatsapp from "./Images/whatsapp.png";

const SidebarLayout = ({ children }) => {
  return (
    <>
      <div className="relative">{children}</div>
      <div className="fixed top-[80%] md:top-[75%] right-5 md:right-10 animate-bounce ">
        <div className="w-[50px] md:w-[70px] cursor-pointer h-[90px]">
          <a href="https://wa.me/+923339114273" target="_blank">
            <img src={Whatsapp} alt="" />
          </a>
        </div>
      </div>
    </>
  );
};

export default SidebarLayout;
