import React, { useState, useEffect } from "react";
import Menu from "../menu/Menu";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function CheckOut() {
  const navigate = useNavigate();
  const id = JSON.parse(localStorage.getItem("checkoutProductIds")); // Parse the JSON array of product IDs
  const subtotel = localStorage.getItem("subtotel");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneno: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    subtotal: subtotel,
    products: id, // Directly set the product IDs obtained from localStorage
    titles: id?.map(productId => ({ product_id: productId })),
  });

  useEffect(() => {
    // Retrieve token from localStorage and set it in the Axios headers
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    // Fetch product titles
  }, []);

  // const fetchProductTitles = async (productIds) => {
  //   try {
  //     console.log("Product IDs:", productIds); // Log the product IDs to check if they are valid
  //     const titles = [];
  //     for (const productId of productIds) {
  //       const response = await axios.get(
  //         `https://mrbookbackend-96a285b41bf3.herokuapp.com/userinformation-get/${productId}`
  //       );
  //       titles.push(response.data.title);
  //       console.log("Title:", response.data.title); // Log the title of each product
  //     }
  //     setFormData((prevData) => ({ ...prevData, titles }));
  //   } catch (error) {
  //     console.error("Error fetching product titles:", error);
  //   }
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      const token = localStorage.getItem("token");

      const data = {
        name: formData.name,
        email: formData.email,
        phoneno: formData.phoneno,
        address: formData.address,
        city: formData.city,
        state: formData.state,
        zipcode: formData.postalCode,
        subtotal: formData.subtotal,
        products: formData.titles
      }

      console.log("here is the data ", data)

      const response = await axios.post(
        "https://mrbookbackend-96a285b41bf3.herokuapp.com/userinformation", data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("here is the response", response)
      if (response.status === 200) {

        const token = localStorage.getItem('token');

        // Empty the cart after successful payment, passing the token
        await axios.delete('https://mrbookbackend-96a285b41bf3.herokuapp.com/api/empty-cart', token);

        // Alert user and redirect after successful order placement


        localStorage.setItem("name", formData.name);
        localStorage.setItem("email", formData.email);
        localStorage.removeItem("checkoutProductIds");
        // Redirect to home page after successful submission
      alert("Book has been order");

        navigate("/");
      } else {
        alert("Error submitting form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <>
      <Menu />
      <h1 className="text-xl md:text-4xl font-bold px-4 flex justify-center items-center font-serif">
        CheckOut
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="px-10 pt-5 w-full ">
          <h1 className="text-2xl font-bold font-serif">User Information</h1>
          <div className="w-full md:w-[50%] pt-8 flex flex-col gap-y-4 md:gap-y-7">
            <div className="w-full flex gap-x-3">
              <input
                type="text"
                required
                placeholder="Name"
                className="rounded-xl w-full py-2 px-5 border border-gray-500"
                name="name"
                onChange={handleChange}
              />
              <input
                type="text"
                required
                placeholder="Email"
                className="rounded-xl w-full px-3 border border-gray-500 py-2"
                name="email"
                onChange={handleChange}
              />
            </div>
            <div className="w-full flex gap-x-3">
              <input
                type="number"
                placeholder="phone No."
                className="rounded-xl w-full py-2 px-5 border border-gray-500"
                required
                name="phoneno"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="w-full md:w-[50%] pt-5 flex flex-col gap-y-4 md:gap-y-7">
            <h1 className="font-bold text-2xl font-serif">Shipping Address</h1>
            <input
              type="text"
              required
              placeholder="Address"
              className="w-full rounded-xl border border-gray-500 py-2 px-3"
              name="address"
              onChange={handleChange}
            />
            <div className="w-full flex gap-x-3">
              <input
                type="text"
                required
                placeholder="City"
                className="rounded-xl w-full py-2 px-5 border border-gray-500"
                name="city"
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="State"
                className="rounded-xl w-full px-3 border border-gray-500 py-2"
                required
                name="state"
                onChange={handleChange}
              />
            </div>
            <div className="w-full flex gap-x-3">
              <input
                type="text"
                placeholder="Postal/Zip code"
                className="rounded-xl w-full py-2 px-5 border border-gray-500"
                required
                name="postalCode"
                onChange={handleChange}
              />
            </div>
            <div className="w-full gap-y-3 flex flex-col mb-5">
              <div className="w-full bg-yellow-500 items-center text-center rounded-2xl">
                <h1 className="text-2xl font-bold p-2">Cash On Delivery</h1>
              </div>
              <button
                type="submit"
                className="py-2 w-full bg-yellow-500 hover:bg-yellow-700 text-black font-serif font-bold rounded-full"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default CheckOut;
