import React, {useState, useEffect} from "react";
import Menu from "../menu/Menu";
// import Delete from "../../Images/delte icon.png";
// import edit from "../../Images/edit icon.png"
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Address() {
  const [newAddress, setNewAddress] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleUpdateAddress = async () => {
    try {

     

      setIsUpdating(true);

      if (!newAddress.trim()) {
        setError("Address cannot be empty");
        setIsUpdating(false);
        return;
      }


      // Your API endpoint URL
      const apiUrl = "https://mrbookbackend-96a285b41bf3.herokuapp.com/api/update-address";

      // Your bearer token
      const token = localStorage.getItem("token");

      // Configuring the Axios request headers
      const axiosConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Making the API request to update the address
      const response = await axios.patch(
        apiUrl,
        { address: newAddress },
        axiosConfig
      );

      // Handle the response as needed
      console.log("Update Address Response:", response.data);

      // Reset the form and state
      setNewAddress("");
      setIsUpdating(false);
      setError(null);

      navigate('/address')
    } catch (error) {
      console.error("Error updating address:", error);
      setIsUpdating(false);
    }
  };

    return (
        <div>
            <Menu />
            <div className="">
                <h1 className="sm:flex items-center justify-center font-bold text-4xl hidden sm:block sm:pt-16">
                    Profile
                </h1>

                <div className="w-full h-full px-2 sm:px-10 pt-10 flex flex-col sm:flex-row gap-x-20">
                    <div className="w-full flex flex-row justify-center sm:flex-col sm:w-[30%] h-full border rounded-2xl">
                        <Link to='/profile' className="py-2 sm:px-2 text-sm w-full items-center text-center border ">Dashboard</Link>
                        <Link to='/address' className="py-2 sm:px-2 text-sm w-full items-center text-center border bg-[#f8b84e]">Address</Link>
                        <Link to='/orderhistory' className="py-2 sm:px-2 text-sm w-full items-center text-center border">Orders History</Link>
                        <Link className="py-2 sm:px-2 text-sm w-full items-center text-center border">Logout</Link>
                    </div>

                    <div className="bg-gray-400 hidden sm:block h-[400px] w-[2px]"></div>
        
                    <div className="w-full px-4 pt-10">
                        <h1 className="text-2xl font-bold">Update Address</h1>

                        
                        <div className="mt-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                New Address:
              </label>
              <input
                type="text"
                value={newAddress}
                onChange={(e) => setNewAddress(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Enter new address"
              />
            </div>

            {error && (
              <p className="text-red-500 text-sm mt-2">{error}</p>
            )}

            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={handleUpdateAddress}
              disabled={isUpdating}
            >
              {isUpdating ? "Updating..." : "Update Address"}
            </button>

                    </div>

                </div>
            </div>

        </div>
    );
}

export default Address;
