import React, {useState, useEffect} from "react";
import Menu from "../menu/Menu";
import Delete from "../../Images/delte icon.png";
import edit from "../../Images/edit icon.png"
import { Link } from "react-router-dom";
import axios from "axios";


function Address() {

    const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Your API endpoint URL
    const apiUrl = "https://mrbookbackend-96a285b41bf3.herokuapp.com/api/usersbyid";

    // Your bearer token
    const token = localStorage.getItem("token");

    // Configuring the Axios request headers
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Making the API request
    axios.get(apiUrl, axiosConfig)
      .then((response) => {
        // Update the state with the fetched user data
        console.log("object", response.data);
        setUserData(response.data.body);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []); // Empty dependency array to run the effect only once



    return (
        <div>
            <Menu />
            <div className="">
                <h1 className="sm:flex items-center justify-center font-bold text-4xl hidden sm:block sm:pt-16">
                    Profile
                </h1>

                <div className="w-full h-full px-2 sm:px-10 pt-10 flex flex-col sm:flex-row gap-x-20">
                    <div className="w-full flex flex-row justify-center sm:flex-col sm:w-[30%] h-full border rounded-2xl">
                        <Link to='/profile' className="py-2 sm:px-2 text-sm w-full items-center text-center border ">Dashboard</Link>
                        <Link to='/address' className="py-2 sm:px-2 text-sm w-full items-center text-center border bg-[#f8b84e]">Address</Link>
                        <Link to='/orderhistory' className="py-2 sm:px-2 text-sm w-full items-center text-center border">Orders History</Link>
                        <Link className="py-2 sm:px-2 text-sm w-full items-center text-center border">Logout</Link>
                    </div>

                    <div className="bg-gray-400 hidden sm:block h-[400px] w-[2px]"></div>

                    <div className="w-full px-4 pt-10">
                        <h1 className="text-2xl font-bold">Your Addresses</h1>

                        <div className="w-full flex gap-x-6">
                            <div>
                                <h1 className="text-xl font-semibold pt-10">{userData?.first_name}</h1>
                                <h1 className="text-sm pt-2">{userData?.address}</h1>
                                <h1 className="text-sm">Mobile: {userData?.contact}</h1>
                            </div>
                            <div className="flex gap-x-3 pt-10">
                                <Link to="/address-edit"><img src={edit}   className="h-[20px] cursor-pointer" alt="" /></Link>
                            </div>
                        </div>



                    </div>

                </div>
            </div>

        </div>
    );
}

export default Address;
