import React, { useState } from 'react';
import Menu from '../menu/Menu';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Payment() {
  const navigate = useNavigate();
  const [isPaymentSuccess, setPaymentSuccess] = useState(false);
  const [formData, setFormData] = useState({
    card_Name: '',
    card_Number: '',
    card_ExpMonth: '',
    card_ExpYear: '',
    card_CVC: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
  
    try {
      // Fetch token from localStorage
      const token = localStorage.getItem('token');
  
      if (!token) {
        console.error('Token not found in localStorage. Please log in.');
        return;
      }
  
      // Fetch amount from /get-subtotal API
      const amountResponse = await axios.get('https://mrbookbackend-96a285b41bf3.herokuapp.com/get-subtotal', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (amountResponse.status === 200) {
        const amount = amountResponse.data.data.subtotal;
        console.log("object", amountResponse.data.data.subtotal);
  
        // Fetch name and email from localStorage
        const name = localStorage.getItem('name') || '';
        const email = localStorage.getItem('email') || '';
  
        console.log('Starting handleSubmit');
  
        // Make the checkout API request with the dynamically fetched data
        const checkoutResponse = await axios.post(
          'https://mrbookbackend-96a285b41bf3.herokuapp.com/checkout',
          {
            name,
            email,
            amount,
            card_Name: formData.card_Name,
            card_ExpYear: formData.card_ExpYear,
            card_ExpMonth: formData.card_ExpMonth,
            card_Number: formData.card_Number,
            card_CVC: formData.card_CVC,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
  
        if (checkoutResponse.status === 200) {
          // Handle successful payment response
          console.log('Payment successful:', checkoutResponse.data);
          setPaymentSuccess(true);
          // You might want to perform additional actions here after a successful payment
        } else {
          // Handle payment error response
          console.error('Error in payment:', checkoutResponse.data);
        }
      } else {
        // Handle error response from /get-subtotal API
        console.error('Error fetching amount:', amountResponse.data);
      }
    } catch (error) {
      console.error('Error in payment:', error);
    }
  };
  
  const closePopup = () => {
    setPaymentSuccess(false);
    navigate('/orderhistory'); // Redirect to /orderhistory on popup close
  };

  return (
    <>
      <Menu />

      <div className="max-w-md mx-auto mt-8 p-6 bg-lightGray rounded-md shadow-md">
        <h1 className="text-xl md:text-4xl font-bold mb-4 text-center">Payment Form</h1>

        <form onSubmit={handleSubmit}>
          {/* Card Holder Name */}
          <div className="mb-4">
            <input
              type="text"
              id="cardHolder"
              name="card_Name"
              value={formData.card_Name}
              onChange={handleChange}
              placeholder="Card Holder Name"
              className="mt-1 p-2 w-full border-b-2 border-yellow-500 rounded-md focus:outline-none focus:border-blue-300"
              required
            />
          </div>

          {/* Card Number */}
          <div className="mb-4">
            <input
              type="text"
              id="cardNumber"
              name="card_Number"
              value={formData.card_Number}
              onChange={handleChange}
              placeholder="Card Number"
              className="mt-1 p-2 w-full border-b-2 border-yellow-500 rounded-md focus:outline-none focus:border-blue-300"
              required
            />
          </div>

          {/* Expiry Date (Month and Year) */}
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <input
                type="text"
                id="expireMonth"
                name="card_ExpMonth"
                value={formData.card_ExpMonth}
                onChange={handleChange}
                placeholder="Expiry Month"
                className="mt-1 p-2 w-full border-b-2 border-yellow-500 rounded-md focus:outline-none focus:border-blue-300"
                required
              />
            </div>
            <div>
              <input
                type="text"
                id="expireYear"
                name="card_ExpYear"
                value={formData.card_ExpYear}
                onChange={handleChange}
                placeholder="Expiry Year"
                className="mt-1 p-2 w-full border-b-2 border-yellow-500 rounded-md focus:outline-none focus:border-blue-300"
                required
              />
            </div>
          </div>

          {/* CVV */}
          <div className="mb-4">
            <input
              type="text"
              id="cvv"
              name="card_CVC"
              value={formData.card_CVC}
              onChange={handleChange}
              placeholder="CVV"
              className="mt-1 p-2 w-full border-b-2 border-yellow-500 rounded-md focus:outline-none focus:border-blue-300"
              required
            />
          </div>

          {/* Submit Button */}
          <div className="text-center">
            <button
              type="submit"
              className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 focus:outline-none focus:ring focus:border-blue-300"
            >
              Submit
            </button>
          </div>
        </form>
        
        {isPaymentSuccess && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="max-w-md p-6 bg-white rounded-md">
              <p className="text-xl font-bold mb-4">Payment Successful!</p>
              <p className="text-gray-600">
                Thank you for your purchase. Your payment was successful. Your books have been ordered.
              </p>
              <button
                className="mt-4 bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 focus:outline-none focus:ring focus:border-blue-300"
                onClick={closePopup}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Payment;
