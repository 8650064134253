import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './component/Home';
import Registration from './component/Registration';
import Login from './component/Login';
import BuyTittle from './component/BuyTittle';
import AboutUs from './component/AboutUs';
import ProductPage from './component/ProductPage';
import Favourite from './component/Favourite';
import ViewCart from './component/ViewCart';
import Gallery from './component/Gallery';
import GalleryBook from './component/GalleryBook';
import Profile from './component/Profile/Profile';
import Address from './component/Profile/Address';
import Addressedit from './component/Profile/Adressedit';
import OrderHistory from './component/Profile/OrderHistory';
import CheckOut from './component/Checkout/CheckOut';
import Payment from './component/Checkout/Payment';
import PrivateRoute from './ProtectedRoute'; // Import PrivateRoute component
import Eventtitle from './component/Eventtitle';
import NewArrivalTittle from './component/NewArrivalTittle';
import SidebarLayout from "./Sidebarlayout";
import Search from './component/Search/Search';
// import PrivateRoutes  from './AuthProvider';


function App() {
  return (
    <Routes>
      <Route path="/" element={<SidebarLayout><Home /></SidebarLayout>} />
      <Route path='/search' element={ <Search/>}/>
      <Route path="/registration" element={<SidebarLayout><Registration /></SidebarLayout>} />
      <Route path="/login" element={<SidebarLayout><Login /></SidebarLayout>} />
      <Route path="/buyTittle/:id" element={<SidebarLayout><BuyTittle /></SidebarLayout>} />
      <Route path="/events/:id" element={<SidebarLayout><Eventtitle /></SidebarLayout>} />
      <Route path="/arrival/:id" element={<SidebarLayout><NewArrivalTittle /></SidebarLayout>} />
      <Route path="/aboutus" element={<SidebarLayout><AboutUs /></SidebarLayout>} />
      <Route path="/ProductPage" element={<SidebarLayout><ProductPage /></SidebarLayout>} />
      <Route path="/Favourite" element={<SidebarLayout><Favourite /></SidebarLayout>} />
      <Route path="/viewCart" element={<SidebarLayout><PrivateRoute Component={ViewCart} /></SidebarLayout>} />
      <Route path="/gallery" element={<SidebarLayout><Gallery /></SidebarLayout>} />
      <Route path="/galleryBook/:id" element={<SidebarLayout><GalleryBook /></SidebarLayout>} />
      <Route path="/profile" element={<SidebarLayout><PrivateRoute Component={Profile} /></SidebarLayout>} />
      <Route path="/address" element={<SidebarLayout><Address /></SidebarLayout>} />
      <Route path="/address-edit" element={<SidebarLayout><Addressedit /></SidebarLayout>} />
      <Route path="/orderhistory" element={<SidebarLayout><OrderHistory /></SidebarLayout>} />
      <Route path="/checkout" element={<SidebarLayout><PrivateRoute Component={CheckOut} /></SidebarLayout>} />
      <Route path="/payment" element={<SidebarLayout><Payment /></SidebarLayout>} />
      {/* Add more routes as needed */}

    </Routes>
  );
}

export default App;
