import React, { useState, useEffect } from "react";
import Menu from "../menu/Menu";
import { Link } from "react-router-dom";

function OrderHistory() {
  const [activesection, setActiveSection] = useState('pending');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        // Retrieve the token from local storage
        const token = localStorage.getItem("token");

        // Fetch orders based on order status
        const response = await fetch('https://mrbookbackend-96a285b41bf3.herokuapp.com/get-cart', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("first", response.data)

        if (response.ok) {
          const data = await response.json();
          setOrders(data.data);
        } else {
          console.error("Failed to fetch orders");
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activesection]);


        console.log("object", orders);

  return (
    <div>
      <Menu />
      <div className="">
        <h1 className="sm:flex items-center justify-center font-bold text-4xl hidden sm:block sm:pt-16">
          Profile
        </h1>

        <div className="w-full h-full px-2 sm:px-10 pt-10 flex flex-col sm:flex-row gap-x-20">
          <div className="w-full flex flex-row justify-center sm:flex-col sm:w-[30%] h-full border rounded-2xl">
            <Link to='/profile' className="py-2 sm:px-2 text-sm w-full items-center text-center border ">Dashboard</Link>
            <Link to='/address' className="py-2 sm:px-2 text-sm w-full items-center text-center border ">Address</Link>
            <Link className={`py-2 sm:px-2 text-sm w-full items-center text-center border ${activesection === 'pending' ? 'bg-[#f8b84e]' : ''}`}
              onClick={() => setActiveSection('pending')}
            >
              Orders History
            </Link>
            <Link className="py-2 sm:px-2 text-sm w-full items-center text-center border">Logout</Link>
          </div>

          <div className="bg-gray-400 hidden sm:block h-[400px] w-[2px]"></div>

          <div className="w-full px-4 pt-10">
            <h1 className="text-2xl font-bold">Order history</h1>

            <div className="flex gap-x-4 pt-4">
              <h1 className={`font-semibold  cursor-pointer ${activesection === 'pending' ? "text-black border-t-2 border-black cursor-pointer font-bold  " : "text-gray-400"
                }`}
                onClick={() => setActiveSection('pending')}
              >
                Pending
              </h1>

              <h1 className={`font-semibold cursor-pointer ${activesection === 'deliver' ? "text-black border-t-2 border-black cursor-pointer font-bold" : "text-gray-400"
                }`}
                onClick={() => setActiveSection('deliver')}
              >
                Delivered
              </h1>

              <h1 className={`font-semibold cursor-pointer ${activesection === "cancelled" ? "text-black border-t-2 border-black cursor-pointer font-bold" : "text-gray-400"
                }`}
                onClick={() => setActiveSection("cancelled")}
              >
                Cancelled
              </h1>
            </div>

            {loading ? (
  <p>Loading...</p>
) : (
  <div>
    {orders.length === 0 ? (
      <p>No orders found.</p>
    ) : (
      <div>
        {orders
          .filter(order => order.order_status === activesection)
          .map((order) => (
            <div key={order._id} className="flex gap-x-3 pt-4">
              <img
                src={order.url} // replace with the correct image path or URL
                className="w-[130px] h-[140px] sm:w-[156px] sm:h-[165px]"
                alt=""
              />
              <div className="flex flex-col gap-y-1 sm:gap-y-1 md:gap-y-5">
                <h1 className="font-semibold text-gray-400">{order.title}</h1>
                <h1 className="font-semibold">{order.salePrice}</h1>
                <h1 className="font-semibold">Total Price: {order.price}</h1>
                <h1 className="font-semibold">Quantity: {order.cartQuantity}</h1>
                <button className="border border-gray-500 text-gray-500 py-1 px-2 rounded-lg w-[100px]">
                  {activesection === 'pending' ? 'Track Order' : 'Add Review'}
                </button>
              </div>
            </div>
          ))}
      </div>
    )}
  </div>
)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
