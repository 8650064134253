import { Link } from 'react-router-dom';
import { RxHamburgerMenu } from 'react-icons/rx'
import { IoMdPerson } from "react-icons/io";
import { MdInsertPhoto } from "react-icons/md";
import { IoMdCart } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";
import logo from '../../Images/logo.jpeg'
import { useNavigate } from 'react-router-dom';

function Menu() {
  const navigate = useNavigate();
  const viewCart = () => {
    navigate('/viewCart')
  }

  const gotoGallery = () => {
    navigate('/gallery')
  }

  const gotoProfile = () => {
    navigate('/profile')
  }

  return (
    <>

      {/* menu for mobile */}
      <div className="w-full block md:hidden bg-yellow-600 px-3 ">
        <div className='flex  py-3 justify-between'>

          <Link to='/' className="flex gap-x-4">
            <img src={logo} alt="" className='h-14 w-16 rounded-2xl' />
          </Link>

          <div className=' flex items-center gap-x-3'>
            <h1 className='text-white text-3xl items-center text-center  font-bold'>Mr Book</h1>
          </div>

          <div className=" flex gap-x-5 text-base hover:text-2xl items-center text-center">
            <IoSearchOutline className='text-2xl text-white cursor-pointer' onClick={() => navigate('/search')} />
            <IoMdCart className='cursor-pointer text-xl text-white' onClick={viewCart} />
            <Link to="/ProductPage">
              <RxHamburgerMenu className='text-2xl text-white' />
            </Link>
          </div>
        </div>
      </div>


      {/* menu for desktop */}

      <div className="hidden md:block bg-yellow-600 md:bg-[#ffffff] top- px-3 w-full">
        <div className=" flex flex-row py-4 w-full justify-between  md:px-0  items-center">
          <div className='flex items-center text-center justify-center md:gap-x-20'>
            <Link to='/' className="flex gap-x-4">
              <img src={logo} alt="" className='h-14 w-16 rounded-2xl' />
            </Link>
            <div className=' flex items-center gap-x-3'>
              <h1 className='text-white text-3xl items-center text-center  font-bold'>Mr Book</h1>
            </div>

            <div className=" md:flex  flex-row gap-x-10 pt items-center font-semibold  ">
              <Link to='/' className='cursor-pointer' href >Home</Link>
              <Link to='/aboutus' className='cursor-pointer' href="#services">About</Link>
              <Link to='/ProductPage' className='cursor-pointer flex' >
                Product
              </Link>
            </div>

          </div>

          <div className='flex gap-x-16 '>
            <div className='relative'>
              <input type="search" placeholder='Search...' className=' px-3 py-2 rounded-lg border cursor-pointer' onClick={() => navigate('/search')} />
            </div>

            <div className='flex pt-1 gap-x-3'>
              <IoMdPerson className='cursor-pointer text-xl ' onClick={gotoProfile} />
              <MdInsertPhoto className='cursor-pointer text-xl ' onClick={gotoGallery} />
              <IoMdCart className='cursor-pointer text-xl ' onClick={viewCart} />
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Menu;
